html {
  font-family: "Noto Sans", serif;
  transform-origin: top left;
  width: 100vw;
  overflow-x: hidden;
}

iframe {
  display: none;
}

#root {
  max-width: 100vw;
}

body {
  max-width: 100vw;
}

.App {
  max-width: 100vw;
}

.container-fluid {
  max-width: 100vw;
}

.transparent {
  background-color: transparent !important;
}

@media only screen and (max-width: 992px) {
  body {
    margin-right: -15%;
    overflow-x: hidden;
  }
}
